
// init the full page slider
$(document).ready(function() {

    $('#fullpage').fullpage({
        scrollOverflow: true,
        paddingTop: 0,
        paddingBottom: '280px',
        controlArrows: true,
        verticalCentered: false,
        loopHorizontal: false
    });

    $(document).delegate('[data-toggle="lightbox"]', 'click', function(event) {
        event.preventDefault();
        $(this).ekkoLightbox();
    });

});